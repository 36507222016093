@import url("https://fonts.googleapis.com/css?family=Poppins:400,700|Lato:300,400,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    
    body {
        font-family: "Poppins", sans-serif;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    h1 a,
    h2 a,
    h3 a,
    h4 a,
    h5 a,
    h6 a {
        font-family: "Poppins", sans-serif;
    }
    p {
        font-size: 16px;
        line-height: 24px;
        @apply text-gray-700;
        font-family: "Lato", sans-serif;
    }

    .container {
        @apply px-5 mx-auto sm:px-20;
    }

    .row {
        @apply flex flex-wrap;
    }

    .section-title .line {
        background: linear-gradient(#fe8464 50%, #fe6e9a 100%);
        @apply w-40 h-1 mb-3;
    }

    .section-title .title {
        @apply pt-2 text-3xl font-bold text-slate-800 dark:text-slate-50 md:text-5xl;
    }

    .gradient-btn {
        background-image: linear-gradient(
            to right,
            #a855f7 0%,
            #6b21a8 50%,
            #a855f7 100%
        );
        background-size: 200% auto;
        overflow: hidden;

        /* @apply px-6 py-3 font-medium text-white hover:bg-purple-400 focus:ring-4 focus:ring-purple-300 focus:outline-none; */
    }
    .gradient-btn-2 {
        background-image: linear-gradient(
            to right,
            #33c8c1 0%,
            #119bd2 50%,
            #33c8c1 100%
        );
    }
    .gradient-btn:hover {
        background-position: right center;
        @apply shadow-xl;
    }
    /* text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 */
    .main-btn {
        @apply inline-block px-6 py-3 font-medium text-white duration-300 bg-blue-600 rounded-lg;
    }
    .main-btn:hover {
        @apply bg-blue-400;
    }
    .gradient-outline-btn{}
    
    .about-shape-1 {
        z-index: -1;
        width: 35%;
        @apply absolute top-0 right-0 h-full;
    }
    .about-shape-2 {
        z-index: -1;
        width: 35%;
        @apply absolute top-0 left-0 h-full;
    }

    .single-counter {
        width: 155px;
        height: 120px;
        border-radius: 60px;
        @apply relative z-10 mt-16;
    }
    .single-counter::before {
        content: "";
        border-radius: 60px;
        z-index: -1;
        @apply absolute top-0 left-0 w-full h-full transform -rotate-45;
    }
    .counter-color-1::before {
        background-image: linear-gradient(#33c8c1 0%, #119bd2 100%);
    }
    .counter-color-2::before {
        background-image: linear-gradient(#fe8464 0%, #fe6e9a 100%);
    }
    .counter-color-3::before {
        background-image: linear-gradient(#361cc1 0%, #2e82ef 100%);
    }

    .subscribe-form .main-btn {
        border-radius: 0 0.25em 0.25em 0;
        @apply absolute top-0 right-0 h-full py-4;
    }
    @media (max-width: 500px) {
        .container {
            @apply px-3;
        }
        .subscribe-form .main-btn {
            @apply static w-full mt-4 rounded;
        }
    }
    .footer-bg {
        z-index: -1;
        @apply absolute bottom-0 left-0 w-full h-full;
    }

    .footer-social li a,
    i {
        @apply inline-block mr-6 text-lg text-slate-800 dark:text-slate-50;
    }
    .footer-social li a:hover {
        @apply text-purple-400;
    }

    .contact li {
        @apply mb-3 text-white;
    }
    .link li a {
        @apply inline-block mb-3 text-base text-slate-800 dark:text-slate-50;
    }

    .link li a:hover {
        @apply text-purple-400;
    }

    input[type='date']:after {
        content: attr(placeholder)
      }

      @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    
    .animate-fade-in {
        animation: fadeIn 3s ease-in-out;
    }
    
 

    @keyframes sway {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(2deg);
        }
        50% {
          transform: rotate(5deg);
        }
        75% {
          transform: rotate(-2deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    
      .animate-sway {
        animation: sway 5s ease-in-out infinite;
      }

    [type="text"],
    [type="range"],
    [type="email"],
    [type="url"],
    [type="password"],
    [type="number"],
    [type="date"],
    [type="datetime-local"],
    [type="month"],
    [type="search"],
    [type="tel"],
    [type="time"],
    [type="week"],
    [multiple],
    .menu-flags,
    textarea,
    select {
        @apply w-full text-xs bg-transparent border-gray-300 rounded-lg shadow-sm sm:text-sm focus:border-indigo-500 focus:ring-indigo-500;
    }
    [type="checkbox"],
    [type="radio"] {
        @apply text-indigo-600 border-gray-300 rounded focus:ring-indigo-500;
    }


    .btn-indigo{
        @apply px-6 py-3 text-sm font-bold text-white bg-indigo-700 rounded whitespace-nowrap hover:bg-orange-500 focus:bg-orange-500;
    }

    .btn-spinner,
.btn-spinner:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}

.btn-spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid white;
  border-right: 0.2em solid white;
  border-bottom: 0.2em solid white;
  border-left: 0.2em solid transparent;
  transform: translateZ(0);
  animation: spinning 1s infinite linear;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bg-hero-signal-blue-dark-100 {
    background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='...' fill='%23000044' fill-opacity='1.0' fill-rule='evenodd'/%3E%3C/svg%3E");
  }
  
  .bg-hero-polka-dots {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  }
  
  @keyframes fly-up {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(-40px) rotate(180deg);
    }
  }
}
